<!-- 提现状态详情 -->
<template>
  <div id="static">
    <div class="box_shadow static_solid">
      <div class="price flex-container">
        <i>￥</i>
        <b>{{ order_data.orderinviter_money }}</b>
      </div>
      <p class="static_tips" v-if="order_data.orderinviter_valid == 1">
        <span class="yes">已结算</span>
      </p>
      <p class="static_tips" v-else-if="order_data.orderinviter_valid == 0">
        <span class="no">待结算</span>
      </p>
      <p class="static_tips" v-else-if="order_data.orderinviter_valid == 2">
        <span class="no">已退款</span>
      </p>
      <p class="static_tips" v-else><span class="no">已取消</span></p>
      <div class="static_data">
        <div class="type1 flex-container">
          <span>类型</span>
          <p class="flex1">返佣</p>
        </div>
        <div class="type2 flex-container">
          <span>申请时间</span>
          <p class="flex1">{{ order_data.orderinviter_addtime | time }}</p>
        </div>
        <div class="type2 flex-container">
          <span>订单号</span>
          <p class="flex1">{{ order_data.orderinviter_order_sn }}</p>
        </div>
        <div
          class="type2 flex-container"
          style="justify-content: space-between"
        >
          <span>产品</span>
          <p class="flex1 w200">{{ order_data.orderinviter_goods_name }}</p>
        </div>
        <div class="type3 flex-container">
          <span class="flex1">处理进度</span>
          <div class="progress">
            <div class="progress1 flex-container">
              <img src="@image/me/static_yes.png" alt="" />
              <div class="solid yes"></div>
              <p class="text yes_color">结算中</p>
              <span class="time flex1">{{
                order_data.orderinviter_addtime | time(2)
              }}</span>
            </div>
            <div
              class="progress2 flex-container"
              v-if="order_data.orderinviter_valid == 0"
            >
              <img src="@image/me/static_wait.png" alt="" />
              <div class="solid2 wait"></div>
              <p class="text wait_color">待结算</p>
              <span class="time flex1"></span>
            </div>
            <div
              class="progress2 flex-container"
              v-if="order_data.orderinviter_valid == 2"
            >
              <img src="@image/me/static_no.png" alt="" />
              <div class="solid2 no"></div>
              <p class="text no_color">已退款</p>
              <span class="time flex1"></span>
            </div>
            <div
              class="progress2 flex-container"
              v-if="order_data.orderinviter_valid == 1"
            >
              <img src="@image/me/static_yes.png" alt="" />
              <div class="solid2 yes"></div>
              <p class="text yes_color">已到账</p>
              <span class="time flex1"></span>
            </div>
            <div
              class="progress2 flex-container"
              v-if="order_data.orderinviter_valid == 3"
            >
              <img src="@image/me/static_wait.png" alt="" />
              <div class="solid2 wait"></div>
              <p class="text wait_color">已取消</p>
              <span class="time flex1"></span>
            </div>
          </div>
        </div>
        <div
          class="type4 flex-container"
          v-if="order_data.orderinviter_valid == 1"
        >
          <span class="flex1">备注</span>
          <p>佣金已到账</p>
        </div>
        <div
          class="type4 flex-container"
          v-if="order_data.orderinviter_valid == 0"
        >
          <span class="flex1">备注</span>
          <p>正在审核中，请稍后等待</p>
        </div>
        <div
          class="type4 flex-container"
          v-if="order_data.orderinviter_valid == 2"
        >
          <span class="flex1">备注</span>
          <p>订单已退款</p>
        </div>
        <div
          class="type4 flex-container"
          v-if="order_data.orderinviter_valid == 3"
        >
          <span class="flex1">备注</span>
          <p>订单已被用户取消</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "@get/http";
import { Toast } from "vant";
export default {
  name: "",
  data: () => ({
    order_data: false,
  }),
  async created() {
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
    let data = await post("/v2.Memberinviter/order_show", {
      data: {
        orderinviter_id: this.$route.query.order_id,
      },
    });
    Toast.clear();
    this.order_data = data;
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#static {
  background: #ebebeb;
  height: 100%;
  padding: rem(84) 0;
}

.static_solid {
  // background: #fff;
  // width: rem(710)!important;
  // box-sizing: border-box;
  // margin:  0 atuo;;
  // box-sizing: border-box;
  // padding-bottom: rem(50);

  .price {
    padding-top: rem(82);
    align-items: center;
    justify-content: center;
    i {
      font-size: rem(48);
      font-weight: bold;
      color: #333333;
      margin-bottom: rem(10);
    }
    b {
      font-size: rem(110);

      font-weight: 400;
      color: #333333;
    }
  }
}

.static_tips {
  font-weight: 500;
  font-size: rem(28);
  text-align: center;
  padding-bottom: rem(54);
  border-bottom: rem(1) solid #ebebeb;
  .yes {
    color: #07c15f;
  }
  .on {
  }
  .wait {
  }
}

.static_data {
  font-size: rem(28);
  font-weight: 500;
  padding: rem(48) rem(32) rem(16);
  box-sizing: border-box;
  .type1,
  .type2,
  .type4 {
    padding: rem(24) 0;
    span {
      color: #b2b2b2;
    }
    p {
      color: #333333;
      text-align: right;
    }
  }
  .type4 {
    p {
      width: rem(324);
    }
  }
  .type3 {
    color: #b2b2b2;
    padding: rem(24) 0 rem(48);

    span {
    }
    .progress {
      width: rem(324);
      .progress1 {
        padding-bottom: rem(52);
      }
      .progress2 {
        padding-top: rem(52);
      }

      .progress1,
      .progress2 {
        position: relative;
        align-items: center;
        img {
          width: rem(30);
          height: rem(30);
          display: block;
        }
        .solid {
          width: rem(4);
          height: rem(58);
          position: absolute;
          left: rem(13);
          top: rem(35);
          z-index: 1;
        }
        .solid2 {
          width: rem(4);
          height: rem(58);
          position: absolute;
          left: rem(13);
          bottom: rem(36);
          z-index: 1;
        }
        .yes {
          background: #07c15f;
        }
        .wait {
          background: #e6e6e6;
        }
        .no {
          background: #ff4747;
        }
        .text {
          font-size: rem(28);

          font-weight: 500;

          margin-left: rem(16);
        }
        .time {
          margin-left: rem(16);
          text-align: right;
          font-size: rem(22);

          font-weight: 500;
        }
        .yes_color {
          color: #07c15f;
        }
        .wait_color {
          color: #b2b2b2;
        }
        .no_color {
          color: #ff4747;
        }
        // .yes_color {
        //   color: #B2B2B2;
        // }
      }
    }
  }
}

.w200 {
  flex: 0 0 rem(400);
}
</style>
